import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { HttpClient } from "@angular/common/http";

import { BroadcastService } from "@azure/msal-angular";
import { MsalService } from "@azure/msal-angular";
import { environment } from "../../../environments/environment";

import { LoginService } from 'src/app/shared/services/login.service';
import { AuthenticationService } from 'src/app/shared/services';
import { Subscription } from 'rxjs';


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  env = environment.version;
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  email: string;
  IsCandidate = false;
  
  isloggedIn;

  //for msal
  public userInfo: any = null;
  public isIframe: boolean;

  public subscription : Subscription = new Subscription();
  test;

  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private authService: MsalService,

    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    //private subscription: Subscription
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard/default']);
    }
  }

  ngOnInit() {

    this.subscription = this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {});

    this.checkoutAccount();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard/default';

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      //console.log('Redirect Success: ', response);
    });

    this.broadcastService.subscribe("msal:loginFailure", payload => {

      console.log("Azure login failure " + JSON.stringify(payload));
    });

    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      //  lil hack for not having login phases
      this.authenticationService.azureLoginSuccess = true;
      this.authenticationService.azure_login(payload).pipe(first())
        .subscribe(
          data => {
            this.router.navigate([this.returnUrl]);
          },
          error => {
            console.log(error);
            //console.log(error);
            //this.alertService.error(error);
            this.loading = false;
          });
    });
    
  }

  candidate(){

    this.IsCandidate = true;

  }

 ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  azure_login() {

    this.authService.loginRedirect({
      scopes: ["user.readwrite", "user.readbasic.all", "profile", "Calendars.ReadWrite"]
    });
    this.authenticationService.azureLoginSuccess = true;

  }

  azure_logout() {
    this.loginService.setUserLoggedIn(false);
    this.authService.logout();
  }

  checkoutAccount() {

    var currentTimeInSeconds=Math.floor(Date.now()/1000);
    console.log(currentTimeInSeconds);

    this.isloggedIn = this.authService.getAccount();

    var exp = this.isloggedIn.idToken.exp;
    console.log(exp);

  
    if (this.isloggedIn && exp>currentTimeInSeconds) {
      this.router.navigate(['/auth/ms-login']);

    }
  }

}
