import { Component } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { ContentLayoutComponent } from '../layout/content-layout/content-layout.component';
import { Subscription } from 'rxjs';
import { AuthenticationService, UserService } from '../../services';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UploadService } from '../../services/upload.service';
import { User } from '../../model';

@Component({
    selector: 'app-rightpanel',
    templateUrl: './app.rightpanel.component.html'
})
export class AppRightpanelComponent {

    amount: SelectItem[];

    selectedAmount: any;

    subscription: Subscription;


    //My Profile
    hrefPrefix: string = `${environment.apiUrl}`;

    current_user;

    user_role = new User();
    user;
    roles;
    darkModeSwitch = false;
    //------------------------------DIALOGS------------------------------

    editDialog = false;

    constructor(
        public app: AppComponent,
        public appMain: ContentLayoutComponent,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
        this.user = new User();
        this.amount = [
            { label: '*****24', value: { id: 1, name: '*****24', code: 'A1' } },
            { label: '*****75', value: { id: 2, name: '*****75', code: 'A2' } }
        ];


        this.loadColorScheme();
    }



    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    logout() {
        this.authenticationService.logout();
      }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    changeColorScheme() {
        let scheme;
        if (this.darkModeSwitch) {
            scheme = 'dark'
        } else {
            scheme = 'light'
        }

        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);

        this.app.darkMode = scheme;
        this.app.topbarTheme = scheme;
        this.app.menuTheme = scheme;

        this.saveColorScheme(scheme);


    }

    changeStyleSheetsColor(id, value, from) {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {           // which function invoked this function - change scheme
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {       // which function invoked this function - change color
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }


    // Funktion zum Speichern des Farbschemas im Cookie
    saveColorScheme(color) {
        // Ablaufzeit des Cookies festlegen (z.B. eine Woche)
        var expiration = new Date();
        expiration.setTime(expiration.getTime() + (7 * 24 * 60 * 60 * 1000));
        // Cookie mit Farbschema und Ablaufzeit erstellen
        document.cookie = "colorScheme=" + color + "; expires=" + expiration.toUTCString() + "; path=/";
    }

    // Funktion zum Laden des Farbschemas aus dem Cookie
    loadColorScheme() {
        // Alle Cookies in einem Array speichern
        var cookies = document.cookie.split(";");
        // Durch alle Cookies iterieren
        for (var i = 0; i < cookies.length; i++) {
            // Aktuelles Cookie in ein Array aufteilen
            var cookie = cookies[i].trim().split("=");
            // Wenn der Name des Cookies "colorScheme" entspricht
            if (cookie[0] === "colorScheme") {
                // Farbschema aus dem Wert des Cookies laden
                var color = cookie[1];
                // Farbschema auf die Seite anwenden (z.B. Hintergrundfarbe ändern)
                this.darkModeSwitch = color == 'light' ? false : true;

                this.changeColorScheme()
                return color;
            }
        }
        // falls kein cookie vorhanden ist
        this.changeColorScheme()
        return "";
    }


}
