export const environment = {
  production: false,
  apiUrl: "https://dev.grandega-access.de/api",
  url: "https://dev.grandega-access.de/v2",
  client_id:"b682311f-5e4b-4c44-bfbd-82f197c3728c",
  version:"Developer Version"
};

// export const environment = {

//   production: true,
//   apiUrl: "https://tst.grandega-access.de/api",
//   url: "https://tst.grandega-access.de",
//   client_id:"8f433791-6535-44ec-94d5-a4c40ba11eff",
//   version:"RELEASE"
// };
