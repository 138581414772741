import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { User } from '../model';
import { Subscription } from 'rxjs';
import { EmployeeService } from '../services/employee.service';
import { NavService } from '../services/nav.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  currentUser: User;
  currentroles: any;
  RoleIDs: any;
  currentUserSubscription: Subscription;
  currentRolesSubscription: Subscription;

  constructor(private authenticationService: AuthenticationService, public router: Router,
    private employeeService: EmployeeService,
    public navServices: NavService

  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));

    if (this.currentUser == null) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      })
    }
    else{

      if (this.RoleIDs == null) {
        this.currentRolesSubscription = this.employeeService.getRoles(this.currentUser.PersonID).subscribe(roles => {
          this.currentroles = roles;
          this.RoleIDs = this.currentroles.map(x => x.RoleID);
          if (this.RoleIDs != null) {
            sessionStorage.setItem('roleID', JSON.stringify(this.RoleIDs));
          }

        });
      } else {
        this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
      };

      //if (this.currentUser || this.authenticationService.azureLoginSuccess) {
      if (true) {
        // authorised so return true

        

        switch (state.url) {
         
          //---------------------------Components--------------------------------------------------

          //Dashboard 103
          case '/dashboard/default':

            return true;

          //---------------------------Full Routes--------------------------------------------------

          case '/error/404':
            return true;

          case '/comingsoon':
            return true;

          case '/maintenance':
            return true;

          case '/forbidden':
            return true;

          case '/auth/login':
            return true;

          default:
           
            var regExp = new RegExp('/expenses/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "114") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/businessdivision/sales.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "114") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/businessdivision/technology.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => el === "107")) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/businessdivision/finance.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "135") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/dashboard/task/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "103") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            this.router.navigate(['/error/404']);
            return false;
        }
        this.router.navigate(['/forbidden']);
        return false;
      }
    }

    // not logged in so redirect to login page with the return url

    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;

  }
}
