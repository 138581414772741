import { Injectable, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EmployeeProfile } from '../model/employeeprofile';
import { User, EmployeeRoles } from '../model';
import { EmployeeService } from './employee.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

// Menu
export interface Menu {
	path?: string;
	company?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	visible?: boolean;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public currentroles
	public RoleIDs
	public currentUser: User
	public IfMenu: boolean = false

	public VisibleAll: boolean = false
	public VisibleTeamleader: boolean = false
	public VisibleAdmin: boolean = false
	public VisibleGehalt: boolean = false
	public VisibleCPS: boolean = false
	public VisibleCRM: boolean = false
	public VisibleMyClient: boolean = false
	public VisibleAway: boolean = false
	public VisiblePR: boolean = false
	public VisibleSchool: boolean = false
	public VisibleStore: boolean = false
	public VisibleInfoDesk: boolean = false
	public VisibleRK: boolean = false
	public VisibleTechAdmin: boolean = false
	public VisibleGB: boolean = false

	public VisibleAdminPayroll: boolean = false
	public VisibleAdminSchool: boolean = false
	public VisibleAdminUser: boolean = false
	public VisibleAdminStore: boolean = false
	public VisibleAdminExpenses: boolean = false
	public VisibleAdminOKR: boolean = false
	public VisibleOKR: boolean = false
	public VisibleAdminCandidate: boolean = false
	public VisibleCandidate: boolean = false
	public VisibleMP: boolean = false
	public VisibleFinance: boolean = false
	public VisibleContracts: boolean = false

	public first
	public dataSource
	items
	MENUITEMS: Menu[]
	public Profile: EmployeeProfile


	constructor(private http: HttpClient) {

		this.onResize();

		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}

	}

	getItems(RoleIDs) {

		this.RoleIDs = RoleIDs;
		if(this.RoleIDs != null){
		this.RoleIDs.forEach(element => {

			switch (element) {

				//TechAdmin
				case '107':
					this.VisibleTechAdmin = true;
					break;

				//Admin
				case '105':
					this.VisibleAdmin = true;
					break;

				//User
				case '103':
					this.VisibleAll = true;
					this.IfMenu = true;
					break;

				//Teamleiter
				case '104':
					this.VisibleTeamleader = true;
					break;

				//Gehalt
				case '111':
					this.VisibleGehalt = true;
					break;

				//CPS
				case '112':
					this.VisibleCPS = true;
					break;


				//Abwesenheiten
				case '115':
					this.VisibleAway = true;
					break;

				//CRM
				case '108':
					this.VisibleCRM = true;
					break;

				//Leistungsnachweise
				case '110':
					this.VisiblePR = true;
					break;

				//School
				case '113':
					this.VisibleSchool = true;
					break;

				//Store
				case '116':
					this.VisibleStore = true;
					break;

				

				//InfoDesk
				case '117':
					this.VisibleInfoDesk = true;
					break;

				//Reisekosten
				case '114':
					this.VisibleRK = true;
					break;

				//AdminUser
				case '118':
					this.VisibleAdminUser = true;
					break;

				//AdminPayroll
				case '121':
					this.VisibleAdminPayroll = true;
					break;

				//AdminExpenses
				case '124':
					this.VisibleAdminExpenses = true;
					break;

				//AdminSchool
				case '119':
					this.VisibleAdminSchool = true;
					break;

				//AdminStore
				case '120':
					this.VisibleAdminStore = true;
					break;

				//MyClient
				case '123':
					this.VisibleMyClient = true;
					break;

				//OKR
				case '125':
					this.VisibleOKR = true;
					break;

				//AdminOKR
				case '126':
					this.VisibleAdminOKR = true;
					break;

				//Managing Partner
				case '122':
					this.VisibleMP = true;
					break;
					
				//Geschaeftsbereich Finance
					case '135':
						this.VisibleFinance = true;
						break;

				//Geschaeftsbereich Vertragwesen
						case '136':
						this.VisibleContracts = true;
						break;

				//AdminCandidate
				//RoleID ändern
				case '107':
					this.VisibleGB = true;
					this.VisibleAdminCandidate = true;
					this.VisibleCandidate = true;
					this.VisibleAll = true;
					break;

				//Bewerber
				case '127':
					this.VisibleCandidate = true;
					this.VisibleAll = true;
					break;

			}
		});
	}
		if (this.IfMenu) {
			this.MENUITEMS = [
				{
					path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', visible: this.VisibleAll || this.VisibleTechAdmin, active: false
				},
				// {
				// 	title: 'Leistungserfassung', icon: 'airplay', type: 'sub', active: false, visible: this.VisiblePR || this.VisibleTechAdmin, children: [
				// 		{ path: '/performance-records/new-pr', title: '+ hochladen', type: 'link', visible: this.VisiblePR || this.VisibleTechAdmin },
				// 		{ path: '/performance-records/my-pr', title: 'Meine Nachweise', type: 'link', visible: this.VisiblePR || this.VisibleTechAdmin }
				// 	]
				// },
				// {
				// 	path: '/my-payrolls', title: 'Gehaltsabrechnungen', icon: 'git-pull-request', type: 'link', visible: this.VisibleGehalt || this.VisibleTechAdmin
				// },
				{
					title: 'Abwesenheiten', icon: 'umbrella', type: 'sub', active: false, visible: this.VisibleAway || this.VisibleTechAdmin, children: [
						{ path: '/leave/leave-list', title: 'Übersicht', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin },
						{ path: '/leave/new-leave', title: 'Neue Abwesenheit', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin }
						//{ path: '/maintenance', title: 'Übersicht', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin },
						//{ path: '/maintenance', title: 'Neue Abwesenheit', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin }
					]
				},
				{
					title: 'grandega School', icon: 'book-open', type: 'sub', active: false, visible: this.VisibleSchool || this.VisibleTechAdmin, children: [
						{ path: '/school/school-list', title: 'Schulungsliste', type: 'link', visible: this.VisibleSchool || this.VisibleTechAdmin },
						{ path: '/school/school-booking', title: 'Meine Buchungen', type: 'link', visible: this.VisibleSchool || this.VisibleTechAdmin },
						//{ path: '/maintenance', title: 'Schulungsliste', type: 'link', visible: this.VisibleSchool },
						//{ path: '/maintenance', title: 'Meine Buchungen', type: 'link', visible: this.VisibleSchool }
					]
				},
				{
					title: 'Reisekosten', icon: 'compass', type: 'sub', active: false, visible: this.VisibleRK || this.VisibleTechAdmin, children: [
						{ path: '/expenses/expenses-overview', title: 'Meine Reisen', type: 'link', visible: this.VisibleRK || this.VisibleTechAdmin },
						{ path: '/expenses/expenses-new', title: 'Neue Reise', type: 'link', visible: this.VisibleRK || this.VisibleTechAdmin }
					]
				},
				// {
				// 	path: '/cps', title: 'Gratifikation', icon: 'dollar-sign', type: 'link', visible: this.VisibleCPS || this.VisibleTechAdmin
				// },
				{
					path: '/crm/dashboard', title: 'XRM', icon: 'grid', type: 'link', visible: this.VisibleCRM || this.VisibleTechAdmin
					//path: '/maintenance', title: 'CRM', icon: 'grid', type: 'link', visible: this.VisibleCRM 
				},
				{
					path: '/my-client/project-assignments', title: 'MyClient', icon: 'monitor', type: 'link', visible: this.VisibleMyClient || this.VisibleTechAdmin
				},
				{
					path: '/okr/work/start', title: 'grandega OKR', icon: 'pie-chart', type: 'link', visible: this.VisibleOKR || this.VisibleTechAdmin
				},
				{
					path: 'https://grandega.sharepoint.com/support/infodesk', title: 'grandega InfoDesk', visible: this.VisibleInfoDesk || this.VisibleTechAdmin, icon: 'git-pull-request', type: 'extTabLink'
				},
				{
					path: 'https://pmcollaboration.sharepoint.com', title: 'PM Collaboration', visible: this.VisibleInfoDesk || this.VisibleTechAdmin, icon: 'git-pull-request', type: 'extTabLink'
				},
				{
					title: 'Administration', icon: 'tool', type: 'sub', active: false, visible: this.VisibleAdmin, children: [
						{ path: '/admin/it-asset-mgmt/dashboard', title: 'Assets', type: 'link', visible: this.VisibleTechAdmin },
						{ path: '/admin/usr-mgmt/start', title: 'Benutzerverwaltung', type: 'link', visible: this.VisibleAdminUser || this.VisibleTechAdmin },
						{ path: '/admin/candidate-mgmt/candidate-list', title: 'Bewerbermanagement', type: 'link', visible: this.VisibleTechAdmin || this.VisibleTechAdmin },
						{ path: '/admin/new-payrolls', title: '+ Gehaltsabrechnungen', type: 'link', visible: this.VisibleAdminPayroll || this.VisibleTechAdmin },
						{ path: '/admin/okr/start', title: 'OKR Verwaltung', type: 'link', visible: this.VisibleAdminOKR || this.VisibleTechAdmin },
						{ path: '/admin/product-mgmt', title: 'Produktverwaltung', type: 'link', visible: this.VisibleAdminStore || this.VisibleTechAdmin },
						{ path: '/admin/expense-mgmt/reports/expenses-sum', title: 'Reisekostenverwaltung', type: 'link', visible: this.VisibleAdminExpenses || this.VisibleTechAdmin },
						{ path: '/admin/school-mgmt/training-list', title: 'Schoolverwaltung', type: 'link', visible: this.VisibleAdminSchool || this.VisibleTechAdmin }
					]
				},
				{
					title: 'Teamleitung', icon: 'users', type: 'sub', active: false, visible: this.VisibleTeamleader || this.VisibleTechAdmin, children: [
						{ path: '/teamleitung/member-list', title: 'Mein Team', type: 'link', visible: this.VisibleTeamleader || this.VisibleTechAdmin }
						// { path: '/teamleitung/ma-care/mgmt-list', title: 'MA-Betreuung', type: 'link', visible: this.VisibleMP || this.VisibleTechAdmin }
					]
				},
				// {
				// 	title: 'grandega Store', icon: 'shopping-cart', type: 'sub', active: false, visible: this.VisibleStore || this.VisibleTechAdmin, children: [
				// 		{ path: '/store/start', title: 'Start', type: 'link', visible: this.VisibleStore || this.VisibleTechAdmin },
				// 		{ path: '/store/add-cart', title: 'Einkaufswagen', type: 'link', visible: this.VisibleStore || this.VisibleTechAdmin }
				// 	]
				// },
				{
					title: 'Geschäftsbereich', icon: 'shopping-cart', type: 'sub', active: false, visible: this.VisibleGB || this.VisibleTechAdmin, children: [
						{ path: '/geschaeftsbereich/finance/dashboard', title: 'Finance', type: 'link', visible: this.VisibleFinance || this.VisibleTechAdmin },
						{ path: '/geschaeftsbereich/contracts/dashboard', title: 'Vertragswesen', type: 'link', visible: this.VisibleContracts || this.VisibleTechAdmin },
						{ path: '/geschaeftsbereich/mp/ma-care/mgmt-list', title: 'MP', type: 'link', visible: this.VisibleMP || this.VisibleTechAdmin }
					]
				},
				// {
				// 	title: 'Mein Bereich', icon: 'tool', type: 'sub', active: false, visible: this.VisibleCandidate || this.VisibleTechAdmin, children: [
				// 		{ path: '/candidates/candidates-docs', title: 'Meine Dokumente', type: 'link', visible: this.VisibleCandidate || this.VisibleTechAdmin }
						

				// 	]
				// },
				{
					path: '/whistleblower/mgmt-new', title: 'Whistleblower-App', icon: 'eye', type: 'link', visible: this.VisibleAll
				},
			]

		};

		this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
		return this.items

	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	ngOnInit() {

	}

	isMobile() {

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
			return true;
		}

		else if (/Chrome/i.test(navigator.userAgent)) {
			return false;
		}

		else {
			return false;
		}
	}

	checkIfLoggedIn(){
		return this.http.get<any>(`${environment.apiUrl}/checkIfLoggedIn`);
	  }
}
