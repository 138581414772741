import { DomSanitizer } from "@angular/platform-browser";
//import { Http, Headers, ResponseContentType } from "@angular/http";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import "rxjs/add/operator/map";
import { Injectable } from "@angular/core";



@Injectable({	providedIn: 'root'})

export class GraphService {
constructor(
   private http: HttpClientModule,
   private sanitizer: DomSanitizer
) {}

transform(html) {
   return this.sanitizer.bypassSecurityTrustUrl(html);
}

getProfileImage() {
    // fetch User Profile Image
    
    // const accessToken= sessionStorage.getItem('currentToken'); //liefter zugangstoken
    // let headers: Headers = new Headers();
    // headers.append("Authorization", "Bearer " + accessToken);
    // this.http
    //   .get("https://graph.microsoft.com/beta/me/photo/$value", {
    //     responseType: ResponseContentType.ArrayBuffer,
    //     headers: headers
    //   })
    //   .map(res => res)
    //   .subscribe(
    //     (data: any) => {
    //       let blob = new Blob([data.arrayBuffer()], {
    //         type: data.headers.get("content-type")
    //       });
    //       let imageUrl = window.URL.createObjectURL(blob);
    //       console.log("Image URL: "+ imageUrl);
    //       console.log(`Image URL2: ${imageUrl}`);
    //       console.log("Transform Url: "+ this.transform(imageUrl))
    //       sessionStorage.setItem("currentTokenURL", imageUrl);
    //       return this.transform(imageUrl);
    //   });
}

}




















// import { Injectable } from '@angular/core';
// //import { Client } from '@microsoft/microsoft-graph-client'; // war import nötig

// import { AuthenticationService } from './authentication.service';
// //import { Event } from './event';
// import { AlertService } from './alert.service';


// import { DomSanitizer } from "@angular/platform-browser";
// import { Http, Headers, ResponseContentType } from "@angular/http";
// import "rxjs/add/operator/map";


// @Injectable({
//   providedIn: 'root'
// })
// export class GraphService {

//   //private graphClient: Client;
//   constructor(
//     private authService: AuthenticationService,
//    //private alertsService: AlertService,
//     private http: Http,
//     private sanitizer: DomSanitizer) {

//     // Initialize the Graph client
//     //this.graphClient = Client.init(
//         {
//       authProvider: async (done) => {
//         // Get the token from the auth service
//         let token = await this.authService.getAccessToken()
//           .catch((reason) => {
//             done(reason, null);
//           });

//         if (token)
//         {
//           done(null, token);
//         } else {
//           done("Could not get an access token", null);
//         }
//       }
//     };
//   }

//   transform(html) {
//     return this.sanitizer.bypassSecurityTrustUrl(html);
//  }

//   getProfileImage() {

//     // fetch User Profile Image
//     let headers: Headers = new Headers();
//     headers.append("Authorization", "Bearer " + AuthenticationService.tokenA);
//     console.log("token:");
//     console.log(AuthenticationService.tokenA);
//     try{
//     this.http
//       .get("https://graph.microsoft.com/beta/me/photo/$value", {
//         responseType: ResponseContentType.ArrayBuffer,
//         headers: headers
//       })
//       .map(res => res)
//       .subscribe(
//         (data: any) => {
//           let blob = new Blob([data.arrayBuffer()], {
//             type: data.headers.get("content-type")
//           });
//           let imageUrl = window.URL.createObjectURL(blob);
//           console.log(imageUrl);
//           console.log("after transform");
//           console.log(this.transform(imageUrl));
//           return this.transform(imageUrl);
//         }
//       );
//     } catch(e) {
//         console.log(e);
//     }
//     }

// //   async getEvents(): Promise<Event[]> {
// //     try {
// //       let result =  await this.graphClient
// //         .api('/me/events')
// //         .select('subject,organizer,start,end')
// //         .orderby('createdDateTime DESC')
// //         .get();

// //       return result.value;
// //     } catch (error) {
// //      // this.alertsService.add('Could not get events', JSON.stringify(error, null, 2));
// //     }
// //   }
// }