import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { SessionOverComponent } from './components/session-over/session-over.component';
import { CustomizerComponent } from './components/customizer/customizer.component';

import {AppMenuitemComponent} from './components/sidebar/sidebarItem/menuitem.component';

import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';

import {RadioButtonModule} from 'primeng/radiobutton'; 
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {BreadcrumbModule} from 'primeng/breadcrumb';

// services
import { NavService } from "./services/nav.service";
import { AuthenticationService } from "./services/authentication.service";
import { GraphService } from './services';

import { MessageModule } from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import { AppRightpanelComponent } from './components/rightpanel/app.rightpanel.component';
import {AvatarModule} from 'primeng/avatar';
import {BadgeModule} from 'primeng/badge';

@NgModule({
  declarations: [
    LoaderComponent,
    SessionOverComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    AppMenuitemComponent,
    AppRightpanelComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    RadioButtonModule,
    InputSwitchModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    InputTextModule,
    BreadcrumbModule,
    ToastModule,
    AvatarModule,
    BadgeModule  
  ],
  exports: [
    LoaderComponent,
  ],
  providers: [
    GraphService,
    NavService,
    AuthenticationService,
    MessageModule
    
  ]
})
export class SharedModule { }

