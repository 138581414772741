import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from '../model';
import { Employee } from '../model/employee';
import { EmployeeProfile } from '../model/employeeprofile';
import { EmployeeRoles } from '../model/employeeroles';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
    role: string;
    
    constructor(private http: HttpClient) { }

    // only for admin
    getAll() {
        return this.http.get<Employee[]>(`${environment.apiUrl}/employees`);
    }

    getAllTeamleader() {
        return this.http.get<any>(`${environment.apiUrl}/teamleader`);
    }

    getMyTeam(id: number){
        return this.http.get<Employee[]>(`${environment.apiUrl}/myteam/${id}`);
    }

    getMyTeamBudget(id: number){
        return this.http.get<any>(`${environment.apiUrl}/myteambudget/${id}`);
    }

    getMyTeamBudgetv2(PersonID: number){
        return this.http.get<Employee[]>(`${environment.apiUrl}/myteambudgetv2/${PersonID}`);
    }

    getMyTeamLeaves(PersonID: number){
        return this.http.get<Employee[]>(`${environment.apiUrl}/myteamleavedays/${PersonID}`);
    }

    getMyTeamMemberProDedications(PersonID: number){
        return this.http.get<any>(`${environment.apiUrl}/pro-dedications/${PersonID}`);
    }

    // only for admin
    getById(id: number) {
        return this.http.get<Employee>(`${environment.apiUrl}/employees/${id}`);
    }

    getRole(id: number) {
        return this.http.get<EmployeeProfile>(`${environment.apiUrl}/employeerole/${id}`);
    }

    getRoles(id: number) {
        return this.http.get<EmployeeRoles>(`${environment.apiUrl}/employeeroles/${id}`);
    }

    //MaCareNew
    getMaCareList(actualCalendarWeek) {
        return this.http.get<any>(`${environment.apiUrl}/maCare/`+actualCalendarWeek);
    }

    getMaCareListByID(PersonID) {
        return this.http.get<any>(`${environment.apiUrl}/maCareByID/`+PersonID);
    }

    // only for admin
    // register(user: User) {
    //   console.log("registered the following user:");
    //   console.log(user);
    //     return this.http.post(`${environment.apiUrl}/users/register`, user);
    // }
    
    updateUser(employee: Employee) {
        return this.http.put<any>(`${environment.apiUrl}/editemployee/${employee.PersonID}`,  employee);
    }

    getProfileData(PersonID: number) {
        return this.http.get<any>(`${environment.apiUrl}/profileData/`+PersonID);
    }

    getAllJzg() {
        return this.http.get<any>(`${environment.apiUrl}/jzgs`);
    }

    deleteJzg(JzgID: number) {
        return this.http.delete<any>(`${environment.apiUrl}/jzg/`+JzgID);
      }

    getVentures() {
        return this.http.get<any>(`${environment.apiUrl}/firmen`);
    }

    getWhistleblows() {
        return this.http.get<any>(`${environment.apiUrl}/whistleblows`);
    }

    getWhistleblowByID(id) {
        return this.http.get<any>(`${environment.apiUrl}/whistleblow/`+id);
    }

    deleteProDedication(ProDedicationID: number) {
        return this.http.delete<any>(`${environment.apiUrl}/prodedication/delete/`+ProDedicationID);
    }
}
