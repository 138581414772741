import { LoginService } from './shared/services/login.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './shared/model';
import { AuthenticationService } from './shared/services';

import {MessageService, PrimeNGConfig} from 'primeng/api';
import { BroadcastService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  // providers: [MessageService]
})
export class AppComponent implements OnInit{

  //primeng
  menuMode = 'sidebar';
  darkMode = 'light';
  topbarTheme = 'light';
  menuTheme = 'light';
  inputStyle = 'outlined';
  ripple: boolean = true;

  public href: string = "";

  title = 'grandegaDev-v2';
  currentUser: User;

  subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private primengConfig: PrimeNGConfig,
    private loginService: LoginService,
    private broadcastService: BroadcastService,
    private messageService: MessageService
    )
  
  {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      
  }

  ngOnInit(){

    this.subscription = this.broadcastService.subscribe("msal:acquireTokenFailure", () => {
      this.messageService.add({ severity: 'info', summary: 'Ausgeloggt', detail: 'Du wurdest aufgrund zu langer Abwesenheit ausgeloggt. Bitte logge dich erneut ein.', life: 3000 });
      


    });

  }

  logout() {
    this.loginService.setUserLoggedIn(false); 
    this.authenticationService.logout();
    this.router.navigate(['/auth/login']);
  }

  

}
