import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../shared/services';

@Component({
  selector: 'app-ms-login',
  templateUrl: './ms-login.component.html',
  styleUrls: ['./ms-login.component.css']
})
export class MsLoginComponent implements OnInit {

  isloggedIn;
  returnUrl;

  constructor(
    private authService: MsalService, 
    private broadcastService: BroadcastService, 
    private authenticationService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard/default';

    this.isloggedIn = this.authService.getAccount();

    var request = {
      scopes: ["user.readwrite", "user.readbasic.all", "profile", "Calendars.ReadWrite"],
      loginHint: this.isloggedIn.preferred_username,
      extraQueryParameters: {domain_hint: 'organizations'}
    }

    this.authService.acquireTokenSilent(request).then((response) => {

      const token = response.accessToken;
      console.log(response);
      this.getLaravel(response);

      }
    ).catch(function (error) {
      console.log(error)
    });


    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      console.log('Redirect Success: ', response);
    });

  }

  getLaravel(response){

    this.authenticationService.azureLoginSuccess = true;

      this.authenticationService.azure_login(response).pipe(first())
        .subscribe(
          data => {
            this.router.navigate([this.returnUrl]);
          },
          error => {
            // this.alertService.error(error);
            // this.loading = false;
          });
  }

}
