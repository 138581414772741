export class Leave {
    LeaveID: number;
    PersonID: number;
    Approver: number;
    Detail: string;
    StartDate: Date;
    EndDate: Date;
    LeaveType: string;
    LeaveStatus: string;
    HalfDayStart: string;
    HalfDayEnd: string;
    TLMessage: string;
    Deleted: string;
    DisplayName: string;
    RequestedDays: number;
    StartDateYear: number;
}