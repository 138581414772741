export class User {
    //id: number;
    PersonID: number;
    EMail: string;
    Password: string;
    Forename: string;
    Surname: string;
    Role: string;
    Token: string;
    DisplayName: string;
    Password_Changed: string;
    CompanyID: number;
    Active: string;
    StreetNumber: string;
    PostalCode: number;
    Residence: string;
    QualificationLevel: string;
    Teamleader: number;
    Mentor: string;
    MentorPlus: string;
    MobileGrandega: string;
    Abbreviation: string;
    PersonType: string;
    ms_oid: number;
    AccountType: string;
    Birthday: string;
}


