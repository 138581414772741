import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import { ContentLayoutComponent } from '../layout/content-layout/content-layout.component';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {

    public RoleIDs
    public currentUser;

    public VisibleAll: boolean = false
    public VisibleInfoDesk: boolean = false
    public VisibleRK: boolean = false
    public VisibleTechAdmin: boolean = false
    public VisibleAdminExpenses: boolean = false
    public VisibleGB: boolean = false
    public VisibleSales: boolean = false

    public VisibleFinance: boolean = false

    model: any[];

    constructor(public appMain: ContentLayoutComponent, private authenticationService: AuthenticationService) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
        this.getItems();
    }

    getItems() {

        if (this.RoleIDs != null) {
            this.RoleIDs.forEach(element => {

                switch (element) {

                    //User
                    case '103':
                        this.VisibleAll = true;
                        break;

                    //InfoDesk
                    case '117':
                        this.VisibleInfoDesk = true;
                        break;

                    //AdminExpenses
                    case '124':
                        this.VisibleAdminExpenses = true;
                        break;

                    //TechAdmin
                    case '107':
                        this.VisibleAll = true;
                        this.VisibleTechAdmin = true;
                        this.VisibleGB = true;
                        this.VisibleSales = true;
                        this.VisibleFinance = true;
                        break;

                    case '141':
                        this.VisibleGB = true;
                        this.VisibleSales = true;
                        break;

                    case '135':
                        this.VisibleGB = true;
                        this.VisibleFinance = true;
                        break;

                }
            })
        }
    }


    ngOnInit() {

        this.model = [
            { label: 'Dashboard', icon: 'pi pi-home', routerLink: ['/dashboard/default'], visible: this.VisibleAll || this.VisibleTechAdmin },
            {
                label: 'Geschäftsbereich', icon: 'pi pi-briefcase', visible: this.VisibleGB || this.VisibleTechAdmin,
                items: [
                    { label: 'Vertrieb', icon: 'pi pi-chart-line', routerLink: ['/businessdivision/sales/dashboard'], visible: this.VisibleSales || this.VisibleTechAdmin },
                    { label: 'Finance', icon: 'pi pi-money-bill', routerLink: ['/businessdivision/finance/dashboard'], visible: this.VisibleFinance || this.VisibleTechAdmin },
                    { label: 'Technologie', icon: 'pi pi-shield', visible: this.VisibleTechAdmin, routerLink: ['/businessdivision/technology/dashboard']},
                ]
            },
        ];
    }
}
