export class PSPElemente {
  PSPElementeID: number;
  PSPName: string;
  active: string;
  Description: string;
  PersonID: string;
  validfrom: Date;
  validto: Date;
  PSPType: string;
  ProjectID: number;
  SellingPrice: number;
  RKrelevant: string;
  IsPublic: string
  AssignmentID: number;
  WorkingHoursDay: number;
  PurchaseOrder: string;
  InvoiceTo: string;
  InvoiceMethod: string;
  OnboardingStartDate: string;
  OnboardingRating: number;
  OnboardingComment: string; 
  BpInvoiceInfo: any;
}
