import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EmployeeService } from '../../services/employee.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {
  
  public currentUser;
  public currentroles;
  public RoleIDs;
  public menuItems  : Menu[];
  public items  : Menu[];
  public text  : string
  public open  : boolean = false;
  public searchResult  : boolean = false;
  public searchResultEmpty  : boolean = false;
  public bookmarkItems : any[] = [];
  currentRolesSubscription: Subscription;

  constructor(
    public navServices: NavService,
    private authenticationService: AuthenticationService,
    private employeeService: EmployeeService) { 

      this.currentUser = this.authenticationService.currentUserValue
    
        // this.employeeService.getRoles(this.currentUser.PersonID).subscribe(roles => {
        //   this.currentroles = roles;
        //   this.RoleIDs = this.currentroles.map(x=>x.RoleID);
        // });
        if(JSON.parse(sessionStorage.getItem('roleID')) == null){
          this.currentRolesSubscription =this.employeeService.getRoles(this.currentUser.PersonID).subscribe(roles => {this.currentroles = roles;
            this.RoleIDs = this.currentroles.map(x=>x.RoleID);
            sessionStorage.setItem('roleID',JSON.stringify(this.RoleIDs));
          });
        }
   }

  ngOnInit() {
    
    this.RoleIDs= JSON.parse(sessionStorage.getItem('roleID'))
    if(this.RoleIDs!= null){
  	this.navServices.getItems(this.RoleIDs).subscribe(menuItems => {
      this.items = menuItems 
      this.items.filter(items => {
        if(items.bookmark){
          this.bookmarkItems.push(items)
        }
      })
    });
  }
  }

  openBookmarkSearch() {
    this.open = !this.open
    this.removeFix();
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) {
     this.open = false
     return this.menuItems = [];
    }  
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
        if(menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link'){
          items.push(menuItems);
        }
        if(!menuItems.children) return false
          menuItems.children.filter(subItems => {
            if(subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
              subItems.icon = menuItems.icon
              items.push(subItems);
            }
            if(!subItems.children) return false
            subItems.children.filter(suSubItems => {
              if(suSubItems.title.toLowerCase().includes(term)) {
                suSubItems.icon = menuItems.icon
                items.push(suSubItems);
              }
            })
        })
          this.checkSearchResultEmpty(items)
          this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    document.getElementById("canvas-bookmark").classList.add("offcanvas-bookmark");
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    document.getElementById("canvas-bookmark").classList.remove("offcanvas-bookmark");
  }

  addToBookmark(items) {
    const index = this.bookmarkItems.indexOf(items);
    if(index === -1 && !items.bookmark){
      items.bookmark = true;
      this.bookmarkItems.push(items)
      this.text = "";
    } else {
      this.bookmarkItems.splice(index, 1);
      items.bookmark = false;
    }
  }

}
