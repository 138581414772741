import { Routes } from '@angular/router';
import { AuthGuard } from '../guard';

export const content: Routes = [
  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Mein Dashboard"
    }
  },
  {
    path: 'businessdivision/sales',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/businessdivision/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Vertrieb"
    }
  },
  {
    path: 'businessdivision/finance',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/businessdivision/finance/finance.module').then(m => m.FinanceModule),
    data: {
      breadcrumb: "Finance"
    }
  },
  {
    path: 'businessdivision/technology',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/businessdivision/technology/technology.module').then(m => m.TechnologyModule),
    data: {
      breadcrumb: "Technology"
    }
  }
]; 