import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, Injectable  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { MsLoginComponent } from './auth/ms-login/ms-login.component';
import { MsalInterceptor, MsalModule } from "@azure/msal-angular";
import { AuthGuard } from './shared/guard/auth.guard';
import { environment } from '../environments/environment';

import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { MenuService } from './shared/services/menu.service';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ConfirmationService, MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { GlobalHttpInterceptorService } from './shared/services/global-http-Interceptor.service';

registerLocaleData(localeDe);

@Injectable({ providedIn: 'root' })

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MsLoginComponent,
  ],
  imports: [
    RippleModule,
    ButtonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    
    //ToastrModule.forRoot(),

    MsalModule.forRoot({
      auth: {
        clientId: environment.client_id,
        redirectUri: environment.url + "/dashboard/default",
        authority: "https://login.microsoftonline.com/grandega.onmicrosoft.com"
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
    }, {
      protectedResourceMap: [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ['https://graph.microsoft.com/v1.0/me/photos/120x120/$value', ['user.read']],
        ['https://graph.microsoft.com/v1.0/me/calendar', ["Calendars.ReadWrite"]],
        ['https://graph.microsoft.com/v1.0/me/calendarview*', ['Calendars.ReadWrite']],
        ['https://graph.microsoft.com/v1.0/me/drive/recent', ['Files.Read']],
        //['https://graph.microsoft.com/v1.0/users/marvin.equit@grandega.de/events', ['User.ReadWrite.All', 'Directory.ReadWrite.All', 'Calendars.ReadWrite']],
        ['https://graph.microsoft.com/v1.0/users/*', ['User.ReadWrite.All', 'Directory.ReadWrite.All', 'Calendars.ReadWrite']],
      ]
    }),

  ],
  providers: [
    MessageService,
    AuthGuard,
    //{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true  },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {provide: LocationStrategy, useClass: HashLocationStrategy}, 
    //{provide: LocationStrategy, useClass: PathLocationStrategy}, 
    ContentLayoutComponent, MenuService, ConfirmationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
