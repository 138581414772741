import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from "primeng/api";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

    constructor(public router: Router, private messageService: MessageService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error) => {

                let handled: boolean = false;
                console.error(error);
                if (error instanceof HttpErrorResponse) {
                    if (error.error instanceof ErrorEvent) {
                    } else {
                        console.log(`error status : ${error.status} ${error.statusText}`);
                        switch (error.status) {
                            case 401:      //login
                                //this.router.navigateByUrl("/auth/login");
                                console.log(`redirect to login`);
                                handled = true;
                                break;
                            case 403:     //forbidden
                                // this.router.navigateByUrl("/auth/login");
                                // console.log(`redirect to login`);
                                handled = true;
                                break;
                            case 500:     //Server Error
                                this.messageService.add({ severity: 'error', summary: 'Information', detail: 'Error 500' });
                                handled = true;
                                break;

                            case 404:     //Not Found
                                this.messageService.add({ severity: 'error', summary: 'Information', detail: 'Error 404' });
                                handled = true;
                                break;

                            case 0:     //Unknown Error
                                //this.router.navigateByUrl("/auth/login");
                                this.messageService.add({ severity: 'error', summary: 'Information', detail: 'Error 0' });
                                handled = true;
                                break;
                        }
                    }
                }
                else {
                    console.error("Other Errors");
                }

                if (handled) {
                    return of(error);
                } else {
                    return throwError(error);
                }

            })
        )
    }

}